@use "./../../assets/scss/variables.scss"as *;

.polygon{
  width: 230px;
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  p{
    color:$dark-purple;
    width: 80%;
    text-align: center;
    font-size: 24px;
    line-height: 36px;
  }
}

@include mobile {
  .polygon{
    width: 120px;
    height: 120px;
    p{
      font-size: 14px;
      line-height: 24px;
    }
  }
}