@use "./assets/scss/variables.scss" as *;

html {
  scroll-behavior: smooth;
 
}

.mobile{
  display: none;
}
.page-container{
  min-height: 61vh;
}
h1{
  font-size: 55px;
}
.blue{
  color: $blue;
}
.purple{
  color: $purple;
}
.center{
  text-align: center;
}
.mw-1200{
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.padded-container{
  padding-left: 50px;
  padding-right: 50px;
}
@include mobile {
  .mobile{
    display: block;
  }
  .desktop{
    display: none !important;
  }
  h1{
    font-size: 30px;
  }
  .padded-container{
    padding-left: 30px;
    padding-right: 30px;
  }
}