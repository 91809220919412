@mixin mobile {
  @media screen and (max-width: 960px) {
      @content;
  }
}

$light-blue: #189BF5;
$blue: #1E95EE;
$white: #ffffff;
$purple: #544F9E;
$dark-purple:#351A79;
$navy: #1E304E;
$gradient: linear-gradient(180deg, #55509F 0%, #0E5A8F 100%)