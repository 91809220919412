@use "./../../assets/scss/variables.scss"as *;


.our-clients{
  margin-bottom: 120px !important;
  h1{
    text-align: center;
    color: $blue;
    margin-top: 0px;
  }
  &-logos{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    margin: 0 auto;
    .logo-container{
      width: 18%;
      img{
        width: 100%;
      }
    }
    
  }
}

@include mobile {
  .our-clients{
    margin-bottom: 60px !important;
    padding: 0 20px;
    &-logos{
      width: 100%;
      .logo-container{
        width: 25%;
      }
    }
  }
}