@use "./../../assets/scss/variables.scss"as *;

.services{
  .web-dev{
    margin-bottom: 150px;
    &-desc{
      display: flex;
      justify-content: center;
      p{
        width: 50%;
        font-size: 24px;
        color: $dark-purple;
        line-height: 35px;
      }
      .img-wrapper{
        width: 30%;
        img{
          width: 100%;
        }
      }
    }
    .polygon-container{
      display: flex;
      justify-content: center;
      margin-bottom: 70px;
    }
  }
  .mobile-dev{
    margin-bottom: 150px;
    &-desc{
      display: flex;
      justify-content: center;
      p{
        font-size: 24px;
        color: $dark-purple;
        line-height: 35px;
      }
      
    }
    .polygon-container{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 70px;
      .polygon-wrapper{
        display: flex;
        flex-direction: column;
        width: 50%;
      }
      &-top{
        display: flex;
      }
      &-bottom{
        display: flex;
        margin-left: 120px;
        >:first-child{
          margin-right: 20px;
        }
      }
      .img-wrapper{
        width: 30%;
        img{
          width: 100%;
        }
      }
    }
  }
  .ui{
    margin-bottom: 150px;
    &-desc{
      display: flex;
      justify-content: center;
      p{
        font-size: 24px;
        color: $dark-purple;
        line-height: 35px;
      }
      
    }
    .polygon-container{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 70px;
      .polygon-wrapper{
        display: flex;
        flex-direction: column;
        width: 50%;
      }
      &-top{
        display: flex;
      }
      &-center{
        display: flex;
        margin-left: 115px;
        >:first-child{
          margin-right: 20px;
        }
      }
      &-bottom{
        display: flex;
      }
      .img-wrapper{
        width: 30%;
        img{
          width: 100%;
        }
      }
    }
  }
  .cloud{
    margin-bottom: 150px;
    .subtitle{
      font-size: 24px;
      color: $dark-purple;
      line-height: 35px;
    }
    &-desc{
      display: flex;
      justify-content: center;
      p{
        width: 50%;
        font-size: 24px;
        color: $dark-purple;
        line-height: 35px;
      }
      .img-wrapper{
        width: 30%;
        img{
          width: 100%;
        }
      }
    }
    .polygon-container{
      display: flex;
      justify-content: center;
      margin-bottom: 70px;
    }
  }
}

@include mobile {
  .services{
    .web-dev{
      margin-bottom: 100px;
      &-desc{
        flex-direction: column;
        p{
          width: 100%;
          font-size: 13px;
          line-height: 24px;
        }
        .img-wrapper{
          width: 100%;
        }
      }
    }
    .mobile-dev{
      margin-bottom: 100px;
      &-desc{
        p{
          font-size: 13px;
          line-height: 24px;
          text-align: center;
        }
      }
      .polygon-container{
        flex-direction: column;
        .polygon-wrapper{
          width: 72%;
        }
        &-bottom{
          margin-left: 60px;
        }
        .img-wrapper{
          width: 60%;
        }
      }
    }
    .ui{
      margin-bottom: 100px;
      &-desc{
        p{
          font-size: 13px;
          line-height: 24px;
          text-align: center;
        }
      }
      .polygon-container{
        flex-direction: column;
        .polygon-wrapper{
          width: 72%;
        }
        &-center{
          margin-left: 60px;
        }
        .img-wrapper{
          width: 60%;
        }
      }
    }
    .cloud{
      margin-bottom: 100px;
      .subtitle{
        font-size: 14px;
      }
      &-desc{
        flex-direction: column;
        p{
          width: 100%;
          font-size: 13px;
          line-height: 24px;
        }
        .img-wrapper{
          width: 100%;
        }
      }
    }
  }
}