@use "./../../assets/scss/variables.scss"as *;

.contact-us{
  .subtitle{
    font-size: 30px;
    word-spacing: 3px;
  }
  &-container{
    display: flex;
    width: 80%;
    margin: 0 auto 80px;
    &-form{
      display: flex;
      flex-direction: column;
      width: 65%;
      align-items: flex-end;
      .MuiTextField-root{
        width: 80%;
        margin-bottom: 20px;
      }
    }
    .MuiDivider-fullWidth{
      border-color:#1C2449;
    }
    &-info{
      display: flex;
      flex-direction: column;
      width: 40%;
      margin-left: 40px;
      justify-content: space-between;
      .contact-details{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        >div{
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          color: $dark-purple;
        }
        img{
          margin-right: 15px;
          width: 30px;
          height: 30px;
          &.location-icon{
            margin-left: 2px;
          }
        }
      }
      .follow-us{
        p{
          color: $purple;
          font-weight: bold;
        }
        .social-links{
          a{
            margin-right: 10px;
            img{
              width: 35px;
              height: 35px;   
            }
          }
        }
      }
    }
  }
}

@include mobile {
  .contact-us{
    .subtitle{
      font-size: 20px;
      width: 80%;
      margin: 0 auto 20px;
    }
    &-container{
      width: 100%;
      flex-direction: column;
      &-form{
        width: 100%;
        align-items: center;
        &.padded-container{
          padding: 0;
        }
      }
      &-info{
        flex-direction: row;
        width: 80%;
        margin: 30px auto;
        flex-wrap: wrap;
        .title{
          font-size: 20px;
          order: 1;
          width: 40%;
        }
        .contact-details{
          order: 3;
          margin-top: -22px;
          span{
            font-size: 13px;
          }
          img{
            margin-right: 10px;
            width: 18px;
            height: 18px;
            &.location-icon{
              margin-left: 2px;
            }
          }
        }
        .follow-us{
          order: 2;
          .social-links{
            a{
              img{
                width: 25px;
                height: 25px;   
              }
            }
          }
        }
      }
    }
  }
}