@use "./../../assets/scss/variables.scss"as *;

.footer{
  display: flex;
  flex-wrap: wrap;
  background-color: $purple;
  color: $white;
  &-wrapper{
    width: 100%;
    display: flex;
    margin: 40px 50px;
    justify-content: space-between;
    .logo-container{
      width: 20%;
      img{
        width: 115px;
      }
    }
    .footer-links{
      display: flex;
      flex-direction: column;
      a{
        text-decoration: none;
        color: $white;
        margin-bottom: 10px;
      }
    }
    .section-title{
      size: 24px;
      font-weight: 700;
    }
    .information-section{
      display: flex;
      flex-direction: column;
    }
    .quick-links-section{
      display: flex;
      flex-direction: column;
    }
    .contact-us-section{
      display: flex;
      flex-direction: column;
      .contact-details{
        >div{
          margin-bottom: 10px;
        }
        img{
          margin-right: 10px;
          &.location-icon{
            margin-left: 2px;
          }
        }
      }
    }
  }
  .copyright{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: $light-blue;
    padding:10px 20px;
    p{
      margin: 0;
    }
    .social-links{
      a{
        margin-right: 10px;
      }
    }
  }
}

@include mobile{
  .footer{
    &-wrapper{
      flex-direction: column;
      .logo-container{
        width: 100%;
        text-align: center;
      }
      .information-section{
        display: none;
      }
    }
    .copyright{
      background-color: $purple;
      margin-bottom: 10px;
      flex-direction: column-reverse;
      align-items: center;
      .social-links{
        margin-bottom: 10px;
      }
    }
  }
}