@use "./../../assets/scss/variables.scss"as *;

.get-in-touch{
  width: 80%;
  margin: 0 auto 120px;
  height: 400px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1{
    text-align: center;
    color: $white;
    margin-top: 0px;
  }
  .contact-btn{
    color:$white;
    background-color: $blue;
    border-radius: 5px;
    padding: 13px 40px;
    text-decoration: none;
  }
}

@include mobile {
  .get-in-touch{
    height: 220px;
    width: 90%;
    margin-bottom: 60px;
    h1{
      font-size: 20px;
      margin-bottom: 20px;
    }
    .contact-btn{
      padding: 8px 20px;
    }
  }
}