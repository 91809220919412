@use "./../../assets/scss/variables.scss"as *;


.about-us{
  .padded-container{
    padding-right: 0;
  }
  &-container{
    display: flex;
    margin-bottom: 50px !important;
    &-details{
      width: 60%;
      .who-we-are-desc{
        font-size: 30px;
      }
    }
    &-img{
      width: 40%;
      img{
        max-width: 100%;
      }
    }
  }
  &-more{
    background: $gradient;
    margin: 70px 0;
    font-size: 32px;
    padding: 60px 70px;
    color: $white;
    font-style: italic;
  }
}
@include mobile {
  .about-us{
    .padded-container{
      padding-right: 30px;
    }
    &-container{
      flex-direction: column;
      &-details{
        width: unset;
        .who-we-are-desc{
          font-size: 17px;
        }
      }
      &-img{
        width: 100%;
      }
    }
    &-more{
      background: $gradient;
      margin: 70px 0;
      font-size: 17px;
      padding: 30px 35px;
      color: $white;
      font-style: italic;
    }
  }
}