@use "./../../assets/scss/variables.scss"as *;

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.05);
  padding: 12px 30px;
  height: 95px;

  .logo-wrapper {
    width: 20%;

    img {
      width: 80px;
    }
  }

  .nav-bar {
    display: flex;
    justify-content: space-around;
    width: 60%;

    .header-link {
      text-transform: capitalize;
      font-size: 26px;
      font-weight: 500;
      text-decoration: none;
      color: $navy;

      &:hover,
      &.active {
        color: $light-blue;
      }
    }
  }

  .lang-selector {
    span {
      cursor: pointer;
    }

    .dutch-lang {
      margin-left: 5px;
    }

    .english-lang {
      margin-right: 5px;
    }

    .active {
      color: $light-blue;
      font-weight: 700;
    }
  }
}

@include mobile {
  .header-mobile-nav {
    display: flex !important;

    >img {
      cursor: pointer;
    }

    &-drawer {
      transition: opacity 0.2s ease-in-out;
      display: flex;
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 1;
      left: 0;
      top: 0;
      opacity: 0;

      &-drop {
        transition: width 0.25s ease-in-out 0.25s;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.6);
      }

      &-wrapper {
        opacity: 0;
        transition: all 0.25s ease-in-out 0.25s;
        width: 0%;
        background-color: $white;

        &-content {
          transition: opacity 0.2s ease-in-out 0.5s;
          opacity: 0;
          .logo-wrapper {
            width: 100%;
            display: block;
            text-align: center;
            margin-bottom: 30px;
          }
      
          .nav-bar {
            flex-direction: column;
            width: 100%;
            text-align: center;
            .header-link{
              border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
              padding-top: 7px;
              padding-bottom: 7px;
              font-size: 20px;
              padding:12px 0px;
              color: $purple;
              &:first-child{
                border-top: 0.5px solid rgba(0, 0, 0, 0.5);
              }
            }
          }
      
          .lang-selector {
            display: flex;
            justify-content: center;
            margin-top: 20px;
          }
        }
      }
      &.open {
        opacity: 1;

        .header-mobile-nav-drawer-drop {
          width: 30%;
        }

        .header-mobile-nav-drawer-wrapper {
          opacity: 1;
          width: 70%;
          padding-top: 30px;
          &-content {
            opacity: 1;
          }
        }
      }
    }
  }
  .header-container{
    padding: 20px 30px;
    height: 55px;
    >.logo-wrapper {
      img {
        width: 50px;
      }
    }
  }
}