@use "./../../assets/scss/variables.scss"as *;

.homepage{
  &-banner{
    width: 100%;
    height: 88vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
  }
  &-title, &-subtitle{
    color: $white;
    margin-top: 0px;
    margin-bottom: 40px;
  }
 
  &-subtitle{
    font-style: italic;
  }
  .banner-btn{
    color:$white;
    background-color: $blue;
    border-radius: 5px;
    padding: 13px 40px;
    text-decoration: none;
  }
  &-services{
    margin-top: 80px;
    margin-bottom: 120px;
    padding: 0px 30px;
    h1{
      text-align: center;
      color: $blue;
      margin-top: 0px;
    }
    .services-container{
      display: flex;
      justify-content: space-between;
      .service{
        text-align: center;
        width: 22%;
        &-title{
          color:$purple;
          font-weight: 700;
        }
      }
    }
    
  }
  
  .product-lifecycle{
    text-align: center;
    margin-bottom: 120px;
    h1{
      text-align: center;
      color: $blue;
      margin-top: 0px;
      margin-bottom: 50px;
    }
    img{
      width: 50%;
    }
  }
  .our-values{
    background: $gradient;
    padding: 80px 50px 50px;
    margin-bottom: 120px;
    h1{
      text-align: center;
      color: $white;
      margin-top: 0px;
      margin-bottom: 80px;
    }
    .values-container{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .value{
          width: 30%;
          margin-bottom: 30px;
          &-title{
            color:$white;
            font-weight: 700;
          }
          &-desc{
            color:$white;
          }
      }
    }
  }
 
}

@include mobile {
  .homepage{
    &-subtitle{
      display: none;
    }
    &-title{
      font-size: 25px;
    }
    &-services{
      margin-top: 40px;
      margin-bottom: 60px;
      .services-container{
        flex-wrap: wrap;
        .service{
          width: 47%;
          img{
            width: 100%;
          }
        }
      }
    }
  
    .product-lifecycle{
      display: none;
    }
    .our-values{
      padding: 30px;
      margin-bottom: 60px;
      .values-container{
        .value{
          width: 47%;
        }
      }
    }
    
  }
}